import { gql } from '@apollo/client';

export const ADD_VISITA_LICORSARIATO = gql`
mutation addVisitaLicorsariato($fkCliente: Float!){
  addVisitaLicorsariato(fkCliente: $fkCliente)
}
`;

export const CHANGE_MOSTRAR_SALDO = gql`
mutation changeMostrarSaldo($fkCliente: Float!, $estado: String!){
  changeMostrarSaldo(fkCliente: $fkCliente, estado: $estado)
}
`;