import React, { useEffect, useState } from "react";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { useMutation } from "@apollo/client";
import { SOLICITA_REACTIVACION } from "../../api/login/mutations";
import { Spinner } from "../loader/spinner";
import InputMask from "react-input-mask";

function Input(props) {
    return (
        <InputMask mask={props.mask} value={props.value} onChange={props.onChange} name={props.name} autoFocus={true}
            className='px-1 sm:px-3 py-0 relative bg-white rounded text-5xl sm:text-8xl font-bold shadow focus:ring ease-linear transition-all duration-150 focus:ring-yellow-500 focus:border-yellow-500 border-0 focus:outline-none w-full'
            required>
            {() => (<input
                name={props.name}
                type="tel"
                className='px-1 sm:px-3 py-0 relative bg-white rounded text-5xl sm:text-8xl font-bold shadow focus:ring ease-linear transition-all duration-150 focus:ring-yellow-500 focus:border-yellow-500 border-0 focus:outline-none w-full'
                autoFocus
                required />)}
        </InputMask>
    );
}

export const Alert = ({ cliente, message, setError, setMensaje }) => {
    const [open, setOpen] = useState(false);
    const [telefono, setTelefono] = useState("");
    const [solicita, { data, loading }] = useMutation(SOLICITA_REACTIVACION, {
        onError: (error) => {
            setOpen(false);
            setError(error.graphQLErrors[0].message);
        }
    });

    const handleSolicita = (e) => {
        e.preventDefault();
        let aux_telefono = telefono?.replaceAll(" ", "");
        aux_telefono = aux_telefono.replaceAll("_", "");

        if (aux_telefono.length === 10) {
            solicita({
                variables: {
                    fkCliente: parseFloat(cliente),
                    telefono: aux_telefono
                }
            });
        } else {
            setError("Tiene que ingresar 10 dígitos");
        }
    }

    const handleChange = (e) => {
        const { value } = e.target;
        setTelefono(value);
    }

    useEffect(() => {
        if (data && data?.solicitaReactivacion) {
            setOpen(false);
            setMensaje(data.solicitaReactivacion);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <>
            <div className="flex bg-red-200 rounded-md p-2 mb-2 text-base text-red-600 font-medium">
                <XCircleIcon className="text-red-600 h-6 w-6" />
                {message.includes('desactivada') ?
                    <div className="w-full flex flex-wrap items-center justify-center">
                        <p className="w-full text-center">{message}</p>
                        <button
                            type="button"
                            onClick={() => setOpen(true)}
                            className="w-full bg-purple-600 text-white rounded-md py-0.5 px-0.5 mt-2 flex justify-center items-center">
                            {!loading ? 'Solicitar reactivación' : <Spinner />}
                        </button>
                    </div>
                    :
                    message
                }
            </div>

            {open &&
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-6xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                        Ingrese su número de teléfono
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setOpen(false)}
                                    >
                                        <span className="text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            X
                                        </span>
                                    </button>
                                </div>
                                <div className="relative p-2 flex-auto">
                                    <form onSubmit={handleSolicita}>
                                        <div className="relative px-3 py-2 flex-auto text-lg font-semibold">
                                            <div className="relative w-full mb-3">
                                                <label className="block uppercase text-xs font-semibold mb-2 text-black">
                                                    Número de teléfono
                                                </label>

                                                <Input name="clave" value={telefono} onChange={handleChange} mask={"999 999 9999"} />
                                            </div>
                                            <p>Un asesor se contactará con usted al número que proporcione.</p>
                                        </div>
                                        <div className="flex items-center justify-end px-6 py-2 border-t border-solid border-blueGray-200 rounded-b">
                                            <button
                                                className="text-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                type="button"
                                                onClick={() => setOpen(false)}
                                            >
                                                Cancelar
                                            </button>
                                            <button
                                                className="bg-amber-500 text-black active:bg-amber-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                type="submit"
                                                disabled={loading}
                                            >
                                                Aceptar
                                            </button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            }
        </>
    )
};