import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { ACTIVE_MARQUESINA } from "../../api/header/queries";
import { useQuery } from "@apollo/client";

export const Marquesina = () => {
    const [mostrar, setMostrar] = useState(false);
    const { data } = useQuery(ACTIVE_MARQUESINA);

    useEffect(() => {
        if (data && data?.activeMarquesina) {
            setMostrar(true);
        }
    }, [data]);

    useEffect(() => {
        if (mostrar) {
            setTimeout(() => setMostrar(false), (parseInt(data?.activeMarquesina?.tiempo) * 1000));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mostrar]);

    return (
        mostrar &&
        <Marquee
            speed={30}
            style={{
                backgroundColor: data?.activeMarquesina?.color_fondo,
                color: data?.activeMarquesina?.color_letra
            }}
        >
            <div className="mx-2">
                {data?.activeMarquesina?.texto}
            </div>
        </Marquee>
    )
}