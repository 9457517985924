import React from "react";

const Header = ({ nombres, pk }) => {
    return (
        <header className="shadow bg-amber-400 mb-3">
            <div className="py-4 px-4 sm:px-6 lg:px-8 text-center rounded-sm">
                <div className="flex flex-wrap">
                    <div className="w-full sm:w-1/2 px-3">
                        <span className="text-sm sm:text-base font-bold">Usuario:  </span>
                        <span className="text-sm sm:text-base font-bold text-blue-900">
                            {nombres}
                        </span>
                    </div>

                    <div className="w-full sm:w-1/2 px-3">
                        <span className="text-sm sm:text-base font-bold">Código:  </span>
                        <span className="text-sm sm:text-base font-bold text-blue-900">
                            {pk}
                        </span>
                    </div>
                </div>
            </div>
        </header>
    )
};

export default Header;