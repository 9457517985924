import React from "react";

const Footer = React.memo(function FooterF() {
    return (
        <footer
            className="relative pb-1"
        >
            <div className="container mx-auto px-4">
                <hr className="mb-1 border-b-1 border-amber-500" />
                <div className="flex flex-wrap items-center md:justify-between justify-center">
                    <div className="w-full px-4">
                        <div className="px-2 my-0.5">
                            <div className="text-xs font-semibold py-1 text-center md:text-right">
                                Recargafacil® {new Date().getFullYear()}{" "}<br />
                                ECONOCELL© 2000 - {new Date().getFullYear()}{" "}<br />
                                Todos los derechos reservados
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
});

export default Footer