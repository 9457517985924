import { gql } from '@apollo/client';

export const ALL_PHOTOS_ACTIVAS = gql`
query all_carousel_activo{
  allCarouselActivo {
    pk_imagen
    img
    text
    color_fondo
    color_letra
  }
}
`;

export const FAVORITOS = gql`
query all_favoritos($allFavoritosId: Int!){
  allFavoritos(id: $allFavoritosId) {
    fk_recaudacion
    recaudacion {
      name
      imagen
      href
      tipo_recaudacion
      operadora {
        href
        name
        img
      }
      servicio {
        pk_recaudacion
        href
        name
        imagen
      }
      juego{
        pk_juego
        href
        name
        img
      }
    }
  }
}
`;

export const PRODUCTOS_RANDOM = gql`
query random_productos{
  randomProductos {
    pk_recaudacion
    name
    imagen
    href
    tipo_recaudacion
    operadora {
      href
      name
      img
    }
    servicio {
      pk_recaudacion
      href
      name
      imagen
    }
    juego {
      pk_juego
      href
      name
      img
    }
  }
}
`;

export const ALL_PUBLICIDAD_ACTIVA = gql`
query all_publicidad_activa ($cliente: Int!){
  allPublicidadActiva (cliente: $cliente){
    id
    imagen
    mas_informacion
    link_mas
    nombre_boton
  }
}
`;

export const BUSQUEDA_PRODUCTOS = gql`
query busquedaProductos($texto: String!){
  busquedaProductos(texto: $texto) {
    pk_recaudacion
    name
    imagen
    href
    tipo_recaudacion
    operadora {
      href
      name
      img
    }
    servicio {
      pk_recaudacion
      href
      name
      imagen
    }
    juego {
      pk_juego
      href
      name
      img
    }
  }
}
`;

export const PRODUCTO_CLIENTE_FK_CLIENTE = gql`
query productoClienteFkCliente($fkTienda: Float!, $fkCliente: Float!){
  productoClienteFkCliente(fkTienda: $fkTienda, fkCliente: $fkCliente) {
    codigo
    nombre
    costo
    precio
    pvp
    descripcion
    stock
    visible
    vitrina_clientes {
      visible
    }
    links_producto_clientes {
      enlace
      descripcion
    }
  }
}
`;

export const ALL_PRODUCTO_CLIENTE = gql`
query allProductoCliente ($fkTienda: Float!, $fkCliente: Float!, $patron: String!){
  allProductoCliente (fkTienda: $fkTienda, fkCliente: $fkCliente, patron: $patron){
    codigo
    costo
    descripcion
    imagen
    nombre
    precio
    pvp
    stock
    fk_tienda
    vitrina_clientes {
      id
      pvp
      visible
    }
    links_producto_clientes {
      enlace
      descripcion
    }
  }
}
`;

export const ALL_PRODUCTOS_CLIENTES = gql`
query allProductosClientes ($patron: String!){
  allProductosClientes (patron: $patron){
    codigo
    costo
    descripcion
    imagen
    nombre
    precio
    pvp
    stock
    fk_tienda
  }
}
`;

export const ALL_PRODUCTO_CLIENTE_VITRINA = gql`
query allProductoClienteVitrina($fkCliente: Float!){
  allProductoClienteVitrina(fkCliente: $fkCliente) {
    pvp
    visible
    producto_cliente {
      nombre
      imagen
      pvp
      descripcion
      fk_tienda
      tienda {
        ciudad {
          name
          division_territorial {
            name
          }
        }
      }
    }
  }
}
`;

export const ALL_PRODUCTOS_CLIENTE_VITRINA = gql`
query allProductosClienteVitrina {
  allProductosClienteVitrina {
    nombre
    imagen
    pvp
    descripcion
    fk_tienda
    tienda {
      ciudad {
        name
        division_territorial {
          name
        }
      }
    }
  }
}
`;

export const TIENDAS_BY_USUARIO = gql`
query tiendasByUsuario($fkCliente: Float!){
  tiendasByUsuario(fkCliente: $fkCliente) {
    id
    nombre
    envio_local
    envio_fuera
    horario
    visible
  }
}
`;

export const CONFIGURACION_TIENDA = gql`
query configuracionTienda($fkTienda: Float!){
  configuracionTienda(fkTienda: $fkTienda) {
    envio_local
    envio_fuera
    horario
  }
}
`;

export const ALL_TIENDAS = gql`
query allTiendas {
  allTiendas {
    id
    nombre
    imagen
  }
}
`;

export const CLIENTE_ENCUESTADO = gql`
query clienteEncuestado($fkCliente: Float!, $fkEncuesta: Int!) {
  clienteEncuestado(fkCliente: $fkCliente, fkEncuesta: $fkEncuesta)
}
`;