import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { ADD_VISTA } from "../../api/carousel/mutations";
import { ALL_PUBLICIDAD_ACTIVA, CLIENTE_ENCUESTADO } from "../../api/carousel/queries";
import { useNavigate } from 'react-router-dom';
import { useUserContext } from "../context/userContext";
import Swal from 'sweetalert2';

export default function Publicidad({ children }) {
    const navigate = useNavigate();
    const [mostrar, setMostrar] = useState(false);
    const [mostrar2, setMostrar2] = useState(false);
    const { ctxUser } = useUserContext();
    const [publicidad_activa, { data }] = useLazyQuery(ALL_PUBLICIDAD_ACTIVA, {
        //fetchPolicy: 'network-only',
        onError: (error) => {
            setMostrar(false)
        }
    });
    const [add_vista] = useMutation(ADD_VISTA, {
        onError: (error) => {
            setMostrar(false)
        }
    });
    const [clienteEncuestado, { data: data3 }] = useLazyQuery(CLIENTE_ENCUESTADO, {
        fetchPolicy: 'network-only',
        onError: (error) => console.log(error.graphQLErrors[0].message)
    });

    useEffect(() => {
        if (ctxUser) {
            clienteEncuestado({ variables: { fkCliente: parseFloat(ctxUser?.pk_cliente), fkEncuesta: 1 } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ctxUser]);

    useEffect(() => {
        if (data && data?.allPublicidadActiva) {
            setMostrar(true);
        }
    }, [data]);

    useEffect(() => {
        if (data3 && data3?.clienteEncuestado) {
            if (data3.clienteEncuestado === 'SI') {
                publicidad_activa({ variables: { cliente: parseInt(ctxUser?.pk_cliente) } });
            } else {
                setMostrar2(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data3]);

    useEffect(() => {
        if (mostrar2) {
            Swal.fire({
                title: "¿Te gustaría poder ofrecer el reporte de buró de crédito en tu negocio?",
                text: "El costo por reporte sería de $5, y ganaría de comisión $1.50",
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: "Sí",
                denyButtonText: "No",
                cancelButtonText: "¿Qué es buró?",
                confirmButtonColor: "#65a30d",
                denyButtonColor: "#dc2626",
                cancelButtonColor: "#0284c7",
                reverseButtons: true,
                showLoaderOnConfirm: true,
                showLoaderOnDeny: true,
                icon: "question",
                allowEscapeKey: false,
                allowOutsideClick: false,
                preConfirm: async () => {
                    try {
                        const response = await fetch(`${process.env.REACT_APP_URL_API}`, {
                            method: 'POST',
                            headers: { "Content-Type": "application/json", },
                            body: JSON.stringify({
                                variables: {
                                    fkCliente: parseFloat(ctxUser?.pk_cliente || 0),
                                    fkEncuesta: 1,
                                    respuesta: "SI"
                                },
                                query: "mutation encuestarCliente($fkCliente: Float!, $fkEncuesta: Int!, $respuesta: String!){\nencuestarCliente(fkCliente: $fkCliente, fkEncuesta: $fkEncuesta, respuesta: $respuesta)\n}"
                            })
                        });

                        return response.json();
                    } catch (error) {
                        Swal.showValidationMessage(`Request failed: ${error}`);
                    }
                },
                preDeny: async () => {
                    try {
                        const response = await fetch(`${process.env.REACT_APP_URL_API}`, {
                            method: 'POST',
                            headers: { "Content-Type": "application/json", },
                            body: JSON.stringify({
                                variables: {
                                    fkCliente: parseFloat(ctxUser?.pk_cliente || 0),
                                    fkEncuesta: 1,
                                    respuesta: "NO"
                                },
                                query: "mutation encuestarCliente($fkCliente: Float!, $fkEncuesta: Int!, $respuesta: String!){\nencuestarCliente(fkCliente: $fkCliente, fkEncuesta: $fkEncuesta, respuesta: $respuesta)\n}"
                            })
                        });

                        return response.json();
                    } catch (error) {
                        Swal.showValidationMessage(`Request failed: ${error}`);
                    }
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: 'Gracias por tu respuesta',
                        icon: 'success',
                        confirmButtonColor: "#0284c7"
                    });
                } else if (result.isDenied) {
                    Swal.fire({
                        title: 'Gracias por tu respuesta',
                        icon: 'success',
                        confirmButtonColor: "#0284c7"
                    });
                } else if (result.isDismissed) {
                    Swal.fire({
                        icon: 'info',
                        html: `
                        <p>El Buró de Crédito recopila y gestiona información sobre el historial de pagos y deudas de personas y empresas. Esta información es usada por bancos y otras entidades para decidir si aprueban o no un préstamo</p>
                        <p>El historial crediticio incluye datos sobre préstamos anteriores, tarjetas de crédito, pagos de servicios, deudas pendientes, entre otros</p>
                        `,
                        showDenyButton: true,
                        confirmButtonText: "Sí",
                        denyButtonText: "No",
                        confirmButtonColor: "#65a30d",
                        denyButtonColor: "#dc2626",
                        reverseButtons: true,
                        showLoaderOnConfirm: true,
                        showLoaderOnDeny: true,
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        preConfirm: async () => {
                            try {
                                const response = await fetch(`${process.env.REACT_APP_URL_API}`, {
                                    method: 'POST',
                                    headers: { "Content-Type": "application/json", },
                                    body: JSON.stringify({
                                        variables: {
                                            fkCliente: parseFloat(ctxUser?.pk_cliente || 0),
                                            fkEncuesta: 1,
                                            respuesta: "SI"
                                        },
                                        query: "mutation encuestarCliente($fkCliente: Float!, $fkEncuesta: Int!, $respuesta: String!){\nencuestarCliente(fkCliente: $fkCliente, fkEncuesta: $fkEncuesta, respuesta: $respuesta)\n}"
                                    })
                                });

                                return response.json();
                            } catch (error) {
                                Swal.showValidationMessage(`Request failed: ${error}`);
                            }
                        },
                        preDeny: async () => {
                            try {
                                const response = await fetch(`${process.env.REACT_APP_URL_API}`, {
                                    method: 'POST',
                                    headers: { "Content-Type": "application/json", },
                                    body: JSON.stringify({
                                        variables: {
                                            fkCliente: parseFloat(ctxUser?.pk_cliente || 0),
                                            fkEncuesta: 1,
                                            respuesta: "NO"
                                        },
                                        query: "mutation encuestarCliente($fkCliente: Float!, $fkEncuesta: Int!, $respuesta: String!){\nencuestarCliente(fkCliente: $fkCliente, fkEncuesta: $fkEncuesta, respuesta: $respuesta)\n}"
                                    })
                                });

                                return response.json();
                            } catch (error) {
                                Swal.showValidationMessage(`Request failed: ${error}`);
                            }
                        },
                    }).then((result) => {
                        if (result.isConfirmed) {
                            Swal.fire({
                                title: 'Gracias por tu respuesta',
                                icon: 'success',
                                confirmButtonColor: "#0284c7"
                            });
                        } else if (result.isDenied) {
                            Swal.fire({
                                title: 'Gracias por tu respuesta',
                                icon: 'success',
                                confirmButtonColor: "#0284c7"
                            });
                        }
                    });
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mostrar2]);

    const CerrarPublicidad = () => {
        add_vista({ variables: { fk_cliente: ctxUser?.pk_cliente, fk_publicidad: data?.allPublicidadActiva?.id } });
        setMostrar(false);
    }

    const CerrarPublicidadA = () => {
        add_vista({ variables: { fk_cliente: ctxUser?.pk_cliente, fk_publicidad: data?.allPublicidadActiva?.id } });
        setMostrar(false);
        if (data?.allPublicidadActiva?.link_mas.includes('http') || data?.allPublicidadActiva?.link_mas.includes('tel:+')) {
            window.open(`${data?.allPublicidadActiva?.link_mas}`, '_blank', 'noopener, noreferrer');
        } else {
            navigate(`${data?.allPublicidadActiva?.link_mas}`);
        }
    }

    return (
        <>
            <div className="z-40 fixed top-0 left-0 w-screen h-screen"
                style={!mostrar ? { display: 'none' } : void 0}
            >
                <div className="flex flex-wrap w-screen px-5 mt-6">
                    <div className="w-full flex items-center justify-center mb-4 sm:mb-6">
                        <div className={(data?.allPublicidadActiva?.mas_informacion === 'S' ? 'w-1/2 sm:items-end sm:text-right' : 'w-full') + " px-4 items-center text-center"}>
                            <button onClick={CerrarPublicidad}>
                                <div className="rounded-full bg-white w-12 sm:w-16 text-3xl sm:text-4xl text-center pb-3 font-semibold">
                                    x
                                </div>
                            </button>
                        </div>
                        {data?.allPublicidadActiva?.mas_informacion === 'S' &&
                            <div className="w-1/2 items-center sm:items-start text-center sm:text-left">
                                <button onClick={CerrarPublicidadA}
                                    alt="Más información" className="rounded-lg bg-white w-full sm:w-60 text-lg sm:text-xl text-center py-1 sm:py-3 font-semibold uppercase break-words">
                                    {data?.allPublicidadActiva?.nombre_boton}
                                </button>
                            </div>
                        }
                    </div>
                    <div className="w-full flex items-center justify-center max-h-96">
                        <img src={`${process.env.REACT_APP_URL_RES}${data?.allPublicidadActiva?.imagen}`} alt="prueba" className="w-full h-full object-center object-contain" />
                    </div>
                </div>
            </div>
            <div className={(!mostrar ? '' : 'offline__overlay--visible ') + 'offline__overlay'} />

            {children}
        </>
    );
};