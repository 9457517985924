import { ArrowRightIcon, XCircleIcon, XMarkIcon } from "@heroicons/react/20/solid"
import { useEffect, useState } from "react";
import { useUserContext } from "../context/userContext";
import { Alert2 } from "../alerts/alert2";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { patterns } from "../form/patterns";
import { publicIpv4 } from 'public-ip';
import { InputPassword } from "../input/inputPassword";

export const PanelMultipago = ({ token, setCtxMP, ctxMP, setCargando, addMultipago, openOtp, setOpenOtp, cargando3, trx, setTrx, openPassword, setOpenPassword }) => {
    const synth = window.speechSynthesis;
    const { ctxUser } = useUserContext();
    const [open, setOpen] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);
    const [error, setError] = useState("");
    const [total, setTotal] = useState(0);
    const [pago, setPago] = useState(0);
    const [otp, setOtp] = useState("");
    const [password, setPassword] = useState("");
    
    const handleEliminar = (t) => {
        let aux = trx.filter((i) => i.fk_recaudacion !== t.fk_recaudacion || i.cuenta_destino !== t.cuenta_destino);
        let aux_suma = 0;
        for (const t of aux) {
            aux_suma = aux_suma + parseFloat(t.cantidad) + parseFloat(t.tipo_recaudacion === "recarga" ? ctxUser?.comision_recargas : t.tipo_recaudacion === "juego" ? ctxUser?.comision_juegos : ctxUser?.comision_pagos);
        }
        setTotal(aux_suma);
        setTrx(aux);
        window.localStorage.setItem("multipago", JSON.stringify(aux));
    }

    const handlePagar = () => {
        if (trx.length > 0) {
            setOpen(true);
        } else {
            setError("Tiene que ingresar al menos una transacción");
        }
    }

    const handleOtp = (e) => {
        const { value } = e.target;
        if (value === "" || patterns.numbers.test(value)) {
            setOtp(value);
        }
    }

    const handleMultipagos = async () => {
        try {
            let aux_trx = [];
            const ip = await publicIpv4();
            for (const t of trx) {
                aux_trx.push({
                    cantidad: t.cantidad,
                    cedula: t.cedula,
                    cuenta_destino: t.cuenta_destino,
                    email: t.email,
                    fk_recaudacion: t.fk_recaudacion,
                    nombre: t.nombre,
                    opciones: t.opciones,
                    origen: t.origen,
                    recaudacion: t.recaudacion,
                    secuencia_consulta: t.secuencia_consulta,
                    telefono: t.telefono,
                    tipo: t.tipo,
                    trx: t.trx,
                    token: token,
                    ip: ip,
                    reserva: t.reserva || '',
                    sorteo: t.sorteo || '',
                    boletos: t.boletos || ''
                });
            }
            addMultipago({
                variables: {
                    fkCliente: parseInt(ctxUser?.pk_cliente),
                    detalle: aux_trx
                }
            });
        } catch (error) {
            setError("Error al obtener la IP, recargue la página por favor");
        }
    }

    const handleMultipagosOTP = async (e) => {
        e.preventDefault();
        try {
            let aux_trx = [];
            const ip = await publicIpv4();
            for (const t of trx) {
                aux_trx.push({
                    cantidad: t.cantidad,
                    cedula: t.cedula,
                    cuenta_destino: t.cuenta_destino,
                    email: t.email,
                    fk_recaudacion: t.fk_recaudacion,
                    nombre: t.nombre,
                    opciones: t.opciones,
                    origen: t.origen,
                    recaudacion: t.recaudacion,
                    secuencia_consulta: t.secuencia_consulta,
                    telefono: t.telefono,
                    tipo: t.tipo,
                    trx: t.trx,
                    token: token,
                    ip: ip,
                    reserva: t.reserva || '',
                    sorteo: t.sorteo || '',
                    boletos: t.boletos || '',
                });
            }
            addMultipago({
                variables: {
                    fkCliente: parseInt(ctxUser?.pk_cliente),
                    detalle: aux_trx,
                    otp: otp
                }
            });
        } catch (error) {
            setError("Error al obtener la IP, recargue la página por favor");
        }
    }

    const handleAceptar = () => {
        setCargando(true);
        handleMultipagos();
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleClosePassword = () => setOpenPassword(false);

    const handleAceptarPassword = async (e) => {
        e.preventDefault();
        try {
            let aux_trx = [];
            const ip = await publicIpv4();
            for (const t of trx) {
                aux_trx.push({
                    cantidad: t.cantidad,
                    cedula: t.cedula,
                    cuenta_destino: t.cuenta_destino,
                    email: t.email,
                    fk_recaudacion: t.fk_recaudacion,
                    nombre: t.nombre,
                    opciones: t.opciones,
                    origen: t.origen,
                    recaudacion: t.recaudacion,
                    secuencia_consulta: t.secuencia_consulta,
                    telefono: t.telefono,
                    tipo: t.tipo,
                    trx: t.trx,
                    token: token,
                    ip: ip,
                    reserva: t.reserva || '',
                    sorteo: t.sorteo || '',
                    boletos: t.boletos || '',
                });
            }
            addMultipago({
                variables: {
                    fkCliente: parseInt(ctxUser?.pk_cliente),
                    detalle: aux_trx,
                    otp: otp,
                    password: password
                }
            });
        } catch (error) {
            setError("Error al obtener la IP, recargue la página por favor");
        }
    }

    useEffect(() => {
        if (ctxMP) {
            document.getElementById("continuar-consultando").focus();
            let trxs = JSON.parse(localStorage.getItem("multipago")) || [];
            let aux_total = 0;
            let aux_trx = [];

            for (const t of trxs) {
                aux_total = aux_total + parseFloat(t.cantidad) + parseFloat(t.tipo_recaudacion === "recarga" ? ctxUser?.comision_recargas * t.cantidad : t.tipo_recaudacion === "juego" ? ctxUser?.comision_juegos : ctxUser?.comision_pagos);
                aux_trx.push({
                    cantidad: t.cantidad,
                    cedula: t.cedula,
                    cuenta_destino: t.cuenta_destino,
                    email: t.email,
                    fk_recaudacion: t.fk_recaudacion,
                    nombre: t.nombre,
                    opciones: t.opciones,
                    origen: t.origen,
                    recaudacion: t.recaudacion,
                    secuencia_consulta: t.secuencia_consulta,
                    telefono: t.telefono,
                    tipo: t.tipo,
                    tipo_recaudacion: t.tipo_recaudacion,
                    trx: t.trx,
                    comision: t.tipo_recaudacion === "recarga" ? ctxUser?.comision_recargas * t.cantidad : t.tipo_recaudacion === "juego" ? ctxUser?.comision_juegos : ctxUser?.comision_pagos,
                    total: parseFloat(t.cantidad) + parseFloat(t.tipo_recaudacion === "recarga" ? ctxUser?.comision_recargas * t.cantidad : t.tipo_recaudacion === "juego" ? ctxUser?.comision_juegos : ctxUser?.comision_pagos),
                    reserva: t.reserva || '',
                    sorteo: t.sorteo || '',
                    boletos: t.boletos || '',
                });
            }
            setTrx(aux_trx);
            setTotal(aux_total);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ctxMP]);

    useEffect(() => {
        let aux_t = `${parseFloat(total || 0).toFixed(2)}`.split('.');
        let aux = `${trx.length} ${trx.length > 1 ? " transacciones " : " transacción "} por un valor de ${parseInt(aux_t?.[0] || 0) !== 1 ? `${aux_t?.[0]} dólares` : `${aux_t?.[0]} dólar`} con ${aux_t?.[1]} centavos`;
        const utterThis = new SpeechSynthesisUtterance(aux);
        const voices = synth.getVoices();
        utterThis.voice = voices[1];
        utterThis.pitch = 1;
        utterThis.rate = 1;

        if (cargando3) {
            synth.speak(utterThis);
        } else {
            synth.cancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cargando3]);

    return (
        <>
            <div className="fixed bottom-0 left-0 right-0 z-50 w-full py-4 px-1 overflow-y-auto transition-transform bg-white transform-none" aria-labelledby="drawer-bottom-label">
                <h5 className="inline-flex items-center mb-4 text-base font-semibold">
                    MultiPagos - Transacciones <InformationCircleIcon className="h-6 w-6 text-blue-700 cursor-pointer ml-2" onClick={() => { setOpenInfo(true) }} />
                </h5>
                <button
                    type="button"
                    onClick={() => { setCtxMP(false) }}
                    className="bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 right-2.5 inline-flex items-center justify-center" >
                    <XMarkIcon className="w-5 h-5" />
                </button>

                <div className="w-full mb-6 text-sm sm:text-base overflow-x-auto max-h-96">
                    <table className="w-full">
                        <thead className="text-right sticky top-0 bg-white py-1">
                            <tr>
                                <th>ID</th>
                                <th>Recaudacion</th>
                                <th>Cuenta</th>
                                <th>Valor</th>
                                <th>Comi.</th>
                                <th>Total</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className="text-right">
                            {trx.map((t, index) => (
                                <tr key={index} className="py-1">
                                    <td>{index + 1}</td>
                                    <td className="break-words">{t.recaudacion.toUpperCase()}</td>
                                    <td>{t.cuenta_destino}</td>
                                    <td>{parseFloat(t.cantidad || 0).toFixed(2)}</td>
                                    <td>{parseFloat(t.comision || 0).toFixed(2)}</td>
                                    <td>{parseFloat(t.total || 0).toFixed(2)}</td>
                                    <td className="px-1"><XCircleIcon className="text-red-600 h-5 w-5 cursor-pointer" onClick={() => { handleEliminar(t) }} /></td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot className="text-right sticky bottom-0 bg-white py-1 border-t border-t-black">
                            <tr>
                                <th colSpan={5}>Total:</th>
                                <th className="bg-rose-300 text-right">{parseFloat(total || 0).toFixed(2)}</th>
                                <th></th>
                            </tr>
                            <tr>
                                <th colSpan={5}>Pago:</th>
                                <th>
                                    <input
                                        type="tel"
                                        value={pago}
                                        onChange={(e) => {
                                            if (patterns.money.test(e.target.value) || e.target.value === "") {
                                                setPago(e.target.value);
                                            }
                                        }}
                                        onFocus={(e) => e.target.select()}
                                        className="outline-none focus:outline-none border-0 text-right p-0 w-full" />
                                </th>
                                <th></th>
                            </tr>
                            <tr>
                                <th colSpan={5}>Cambio:</th>
                                <th className="bg-yellow-300 text-right">{(parseFloat(pago || 0) - parseFloat(total || 0)).toFixed(2)}</th>
                                <th></th>
                            </tr>
                        </tfoot>
                    </table>
                </div>

                <div className="flex justify-end items-end">
                    <button
                        type="button"
                        onClick={handlePagar}
                        className="px-4 py-2 mr-2 text-sm font-bold text-center text-white bg-red-600 border border-red-500 rounded-lg focus:outline-none hover:bg-red-500 focus:z-10 focus:ring-4 focus:ring-gray-200">
                        Pagar
                    </button>

                    <button
                        id="continuar-consultando"
                        type="button"
                        onClick={() => { setCtxMP(false) }}
                        className="inline-flex items-center px-4 py-2 text-sm font-normal text-center text-white bg-blue-700 rounded-lg hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 focus:outline-none">
                        Continuar consultando
                        <ArrowRightIcon className="w-6 h-6 ml-2" />
                    </button>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>

            {open ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-6xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                        ¿Realizar el pago?
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={handleClose}
                                    >
                                        <span className="text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            X
                                        </span>
                                    </button>
                                </div>
                                <div className="relative p-6 flex-auto">
                                    <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                                        ¿Está seguro de que desea realizar el pago por ${parseFloat(total || 0).toFixed(2)}?
                                    </p>
                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="text-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={handleClose}
                                    >
                                        Cancelar
                                    </button>
                                    <button
                                        className="bg-amber-500 text-black active:bg-amber-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={handleAceptar}
                                    >
                                        Aceptar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}

            {openInfo &&
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-6xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                        ¿Cómo utilizar la opción Multipagos?
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => { setOpenInfo(false) }}
                                    >
                                        <span className="text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            X
                                        </span>
                                    </button>
                                </div>
                                <div className="relative p-6 flex-auto">
                                    <div className="my-4 text-lg leading-relaxed overflow-y-auto max-h-80">
                                        Esta opción permite agrupar varias transacciones (recargas, pagos, otros productos ofertados) y pagarlos al mismo tiempo con un solo clic.
                                        <br />
                                        Para agregar una transacción al grupo haga clic en el botón
                                        <div
                                            className="mx-2 inline-flex items-center px-4 py-2 text-sm font-normal text-center text-white bg-blue-700 rounded-lg">
                                            Continuar consultando
                                            <ArrowRightIcon className="w-6 h-6 ml-2" />
                                        </div>
                                        y siga el proceso normal de realizar una recarga o un pago.
                                        <br />
                                        Una vez tenga en la lista todas las transacciones a pagar haga clic en el botón
                                        <div
                                            className="inline-flex items-center px-4 py-2 mx-2 text-sm font-bold text-center text-white bg-red-600 border border-red-500 rounded-lg">
                                            Pagar
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-center px-6 py-3 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="bg-blue-600 text-white active:bg-blue-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => { setOpenInfo(false) }}
                                    >
                                        Aceptar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>}

            {
                openOtp &&
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-6xl">
                            <form onSubmit={handleMultipagosOTP} className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                        Ingresar el código enviado a su teléfono o email
                                    </h3>
                                    <button
                                        type='button'
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setOpenOtp(false)}
                                    >
                                        <span className="text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            X
                                        </span>
                                    </button>
                                </div>
                                <div className="relative p-6 flex-auto">
                                    <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                                        <div className="relative w-full mb-3">
                                            <label className="block uppercase text-xs font-bold mb-2">
                                                Código OTP
                                            </label>

                                            <input
                                                type="tel"
                                                value={otp}
                                                onChange={handleOtp}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                className="inp-md"
                                                required
                                                autoFocus
                                            />
                                        </div>
                                    </p>
                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="text-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setOpenOtp(false)}
                                    >
                                        Cancelar
                                    </button>
                                    <button
                                        className="bg-amber-500 text-black active:bg-amber-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="submit"
                                    >
                                        Aceptar
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            }

            {
                openPassword &&
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-6xl">
                            <form onSubmit={handleAceptarPassword} className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                        Ingrese su contraseña de inicio de sesión
                                    </h3>
                                    <button
                                        type="button"
                                        className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={handleClosePassword}
                                    >
                                        <span className="text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            X
                                        </span>
                                    </button>
                                </div>
                                <div className="relative px-6 py-4 flex-auto">
                                    <p>Se ha detectado un cambio de dirección IP, por favor ingrese su contraseña para continuar o inicie sesión e intente hacer la transacción nuevamente</p>
                                    <div className="mt-6 text-lg leading-relaxed">
                                        <InputPassword
                                            name="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            label="Contraseña"
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="text-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={handleClosePassword}
                                    >
                                        Cancelar
                                    </button>
                                    <button
                                        className="bg-amber-500 text-black active:bg-amber-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="submit"
                                    >
                                        Aceptar
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            }

            {
                error &&
                <Alert2 message={error} setError={setError} />
            }
        </>
    )
}