import { createContext, useContext, useState } from "react";

const MultipagoContext = createContext({});

export const MultipagoContextProvider = ({ children }) => {
    const [ctxMP, setCtxMP] = useState(false);

    return (
        <MultipagoContext.Provider value={{ ctxMP, setCtxMP }}>
            {children}
        </MultipagoContext.Provider>
    )
};

export const useMultipagoContext = () => useContext(MultipagoContext);