import React, { useEffect, useState } from "react";

export default function Offline({ children }) {
    const [online, isOnline] = useState(navigator.onLine);

    const setOnline = () => {
        isOnline(true);
    };
    const setOffline = () => {
        isOnline(false);
    };

    useEffect(() => {
        window.addEventListener('offline', setOffline);
        window.addEventListener('online', setOnline);

        return () => {
            window.removeEventListener('offline', setOffline);
            window.removeEventListener('online', setOnline);
        }
    }, []);

    return (
        <>
            <div className="offline"
                style={online ? { display: 'none' } : void 0}
            >
                <div className="offline__content">
                    <div className="offline__text">
                        <h1 className="text-center text-2xl font-bold">Estás offline</h1>
                        <p className="text-center text-lg">Revisa tu conexión a internet para seguir navegando</p>
                    </div>
                </div>
            </div>
            <div className={(online ? '' : 'offline__overlay--visible ') + 'offline__overlay'} />
            {children}
        </>
    );
};