import { gql } from '@apollo/client';

export const ACTIVE_MARQUESINA = gql`
query active_marquesina{
  activeMarquesina {
    texto
    tiempo
    color_fondo
    color_letra
  }
}
`;

export const ALL_IMG_PUBLICIDAD_ACTIVA = gql`
query all_img_publicidad_activa{
  allImgPublicidadActiva {
    imagen
    mensaje
    pie
  }
}
`;

export const PERMISOS_ADMINISTRADOR = gql`
query permisos_administrador ($pk: Int!) {
  permisosAdministrador (pk: $pk) {
    rol
  }
}
`;

export const ME = gql`
query me {
  me {
    pk_cliente
    nombres
    saldo
    puntos
    rol
    nombre_comercio
    pantalla_inicial
    tiempo_inactividad
    confirmar_huella
    multipagos
    comision_recargas
    comision_pagos
    comision_juegos
    autoriza_mp
    multiservicios
    autoriza_ms
    direccion_fija
    direccion
    telefono_movil
    longitud
    latitud
    distancia
    activar_vitrina
    minutos_espera_vitrina
    crea_tiendas
    mostrar_saldo
    consultas
    recargas
    recaudaciones
    bancos
    entretenimiento
  }
}
`;

export const COUNT_MENSAJES = gql`
query count_mensajes($countMensajesId: Int!){
  countMensajes(id: $countMensajesId)
}
`;