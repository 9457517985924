import { LightBulbIcon } from "@heroicons/react/20/solid";

export const Validando = () => {
    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="flex items-center w-full max-w-xs p-4 rounded-lg shadow text-white bg-blue-700" role="alert">
                    <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg bg-white text-blue-700">
                        <LightBulbIcon className="w-6 h-6" />
                    </div>
                    <div className="ml-3 text-base font-normal break-words">
                        Validando....
                    </div>
                </div>
            </div >
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}