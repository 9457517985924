import gql from "graphql-tag";

export const GET_CHALLENGE = gql`
query getChallenge($fkCliente: Int!){
  getChallenge(fkCliente: $fkCliente)
}
`;

export const GET_AUTENTICACION = gql`
query getAutenticacion($fkCliente: Int!){
  getAutenticacion(fkCliente: $fkCliente)
}
`;

export const GET_AUTENTICACION_PAGOS  = gql`
query getAutenticacionPagos($fkCliente: Int!){
  getAutenticacionPagos(fkCliente: $fkCliente)
}
`;