import React, { useEffect, useState } from "react"
import Tooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap.css'
import {
    ChevronLeftIcon,
    ArrowLeftOnRectangleIcon
} from '@heroicons/react/20/solid'
import { useNavigate, Outlet, useLocation } from 'react-router-dom'
import Footer from '../../components/footer/footer'
import Header from "./header_acreditaciones"
import { useApolloClient, useQuery } from "@apollo/client"
import { useIdleTimer } from 'react-idle-timer'
import { AcreditacionContext } from "../context"
import Loader from "../loader/loader"
import { ME } from "../../api/header/queries"
import { Alert } from "../alerts/alerts"

const Sidebar_acreditacion = ({ setToken }) => {
    let location = useLocation();
    const client = useApolloClient();
    const [open, setOpen] = useState(true);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { data, loading } = useQuery(ME, {
        onError: (error) => {
            setError(error.graphQLErrors[0].message)
        }
    });
    const [nombres, setNombres] = useState('');
    const [pk, setPk] = useState('');

    useEffect(() => {
        if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i) || window.innerWidth <= 780) {
            setOpen(false)
        } else {
            setOpen(true)
        }
    }, []);

    useEffect(() => {
        if (data && data?.me) {
            setNombres(`${data.me?.nombres}`)
            setPk(data.me?.pk_cliente)
        }
    }, [data]);

    const logout = () => {
        setToken(null)
        sessionStorage.clear()
        client.clearStore()
        navigate((location.pathname === "/facturas") ? "/comprobante" : "/login_acreditacion", { replace: true })
    };

    const handleOnIdle = () => {
        logout();
    };

    // eslint-disable-next-line no-unused-vars
    const idleTimer = useIdleTimer({
        timeout: 1000 * 60 * 15,
        onIdle: handleOnIdle,
        debounce: 500
    });

    if (loading) return <Loader />

    return (
        <>
            {error &&
                <Alert message={error} />}
            <AcreditacionContext.Provider value={pk}>
                <div
                    className={` ${open ? "w-40 sm:w-56" : "w-16 sm:w-20 "
                        } bg-amber-500 h-screen p-2 sm:p-5 pt-8 relative duration-200 shadow-lg`}>
                    <ChevronLeftIcon
                        className={`absolute cursor-pointer -right-3 top-9 w-6 h-6 border-amber-600 bg-amber-600
           			border-2 rounded-full z-20  ${!open && "rotate-180"}`}
                        onClick={() => setOpen(!open)}
                    />
                    <button onClick={() => navigate('/recargas', { replace: true })}>
                        <div className="flex gap-x-4 items-center">
                            <img
                                src="/img/Nav/logo.png" alt="logo"
                                className={`duration-500 h-9 w-9 ${open && "rotate-[360deg]"
                                    }`}
                            />
                            <h1
                                className={`text-white origin-left font-medium text-sm sm:text-xl duration-200 ${!open && "scale-0"
                                    }`}
                            >
                                RecargaFácil
                            </h1>
                        </div>
                    </button>
                    <div className="pt-6">
                        <Tooltip placement="right" overlay="Salir">
                            <button onClick={logout} aria-label="Salir"
                                className="flex rounded-md my-1 p-2 hover:bg-blue-500 text-white font-semibold text-xs sm:text-sm items-center gap-x-4"
                            >
                                <ArrowLeftOnRectangleIcon className="h-7 w-7 text-red-700" />
                                <span className={`${!open && "hidden"} origin-left duration-150`}>
                                    Salir
                                </span>
                            </button>
                        </Tooltip>
                    </div>
                </div>

                <div className="h-screen flex-1 p-0 overflow-x-auto">
                    <Header nombres={nombres} pk={pk} />
                    <div className="px-2">
                        <Outlet />
                    </div>
                    <Footer />
                </div>
            </AcreditacionContext.Provider>
        </>)
};

export default Sidebar_acreditacion;