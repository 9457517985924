import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid"
import { useState } from "react";

export const InputPassword = ({ name, value, onChange, label, autoFocus, required }) => {
    const [icon, setIcon] = useState(false);
    const [tipo, setTipo] = useState("password");

    function cambiarIcon(e) {
        e.preventDefault()
        setIcon(!icon)
        icon ? setTipo("password") : setTipo("text")
    };

    return (
        <>
            <div className="relative flex w-full flex-wrap items-stretch mb-3">
                <label className="block uppercase text-xs font-bold mb-2">
                    {label}
                </label>

                <input
                    name={name}
                    value={value}
                    type={tipo}
                    className="inp-text"
                    onChange={onChange}
                    autoFocus={autoFocus}
                    required={required}
                />

                <span className="z-10 mt-5 h-full leading-snug font-normal absolute text-center bg-transparent rounded text-base items-center justify-center w-9 sm:w-15 right-0 pr-2 py-3">
                    <div onClick={cambiarIcon} className="cursor-pointer">
                        {icon ? (
                            <EyeSlashIcon className="h-5 w-5 text-black" />
                        ) : (
                            <EyeIcon className="h5 w-5 text-black" />
                        )}
                    </div>
                </span>
            </div>
        </>
    )
}