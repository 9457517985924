import gql from "graphql-tag";

export const REGISTRAR_HUELLA = gql`
mutation registrarHuella($fkCliente: Int!, $payload: String!, $dispositivo: String){
  registrarHuella(fkCliente: $fkCliente, payload: $payload, dispositivo: $dispositivo)
}
`;

export const REGISTRAR_LOGIN = gql`
mutation registrarLogin($fkCliente: Int!, $payload: String!, $ip: String){
  registrarLogin(fkCliente: $fkCliente, payload: $payload, ip: $ip) {
    value
  }
}
`;

export const DELETE_HUELLA = gql`
mutation deleteHuella($id: String!){
  deleteHuella(id: $id)
}
`;

export const REGISTRAR_PAGO = gql`
mutation registrarPago($fkCliente: Int!, $payload: String!){
  registrarPago(fkCliente: $fkCliente, payload: $payload)
}
`;

export const SALIR_MULTISERVICIOS = gql`
mutation salirMultiservicios($fkCliente: Float!, $payload: String!){
  salirMultiservicios(fkCliente: $fkCliente, payload: $payload)
}
`;