/* eslint-disable default-case */
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import {
  ApolloClient, InMemoryCache, ApolloProvider, from, ApolloLink
} from "@apollo/client";
import { createUploadLink } from 'apollo-upload-client';
import Offline from "./components/offline/offline";
//import Publicidad from "./components/carousel/publicidad"
import { onError } from "@apollo/client/link/error";
import { UserContextProvider } from "./components/context/userContext";
import { MultipagoContextProvider } from "./components/context/multipagoContext";

const link = createUploadLink({
  uri: process.env.REACT_APP_URL_API
});

const authLink = new ApolloLink((operation, forward) => {
  const token = (sessionStorage.getItem('information-user') || localStorage.getItem('information-user'))
  const token2 = sessionStorage.getItem('acreditacion-user')
  operation.setContext(({ headers }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : token2 ? `Bearer ${token2}` : "",
    },
  }));

  return forward(operation)
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      switch (err.extensions.code) {
        case "UNAUTHENTICATED":
          const oldHeaders = operation.getContext().headers;
          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: '',
            },
          });
          window.sessionStorage.clear();
          window.localStorage.removeItem("information-user");
          window.dispatchEvent(new Event('storage'));
          client.clearStore();
          window.location.reload();
      }
    }
  }

  if (networkError) { }
});

const client = new ApolloClient({
  link: from([authLink, errorLink, link]),
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ApolloProvider client={client}>
    <Offline>
      <UserContextProvider>
        <MultipagoContextProvider>
          {/*<Publicidad>*/}
          <App />
          {/*</Publicidad>*/}
        </MultipagoContextProvider>
      </UserContextProvider>
    </Offline>
  </ApolloProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.unregister();

serviceWorkerRegistration.register({
  onUpdate: async (registration) => {
    // Corremos este código si hay una nueva versión de nuestra app
    // Detalles en: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
    if (registration && registration.waiting) {
      await registration.unregister()
      registration.waiting.postMessage({ type: "SKIP_WAITING" })
      // Des-registramos el SW para recargar la página y obtener la nueva versión. Lo cual permite que el navegador descargue lo nuevo y que invalida la cache que tenía previamente.
      window.location.reload()
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();