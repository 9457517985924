import React, { useEffect, useState } from "react";
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { Outlet } from "react-router-dom";
import {
    ChevronLeftIcon,
    HomeIcon,
    UserPlusIcon,
    UserGroupIcon,
    PlusCircleIcon,
    DocumentTextIcon,
    ArrowRightEndOnRectangleIcon
} from '@heroicons/react/20/solid';
import { Link, NavLink } from 'react-router-dom';
import Footer from '../../components/footer/footer';

const navigation = [
    { name: 'Inicio', href: '/home', icon: <HomeIcon className="h-6 w-6" />, id: 'inicio' },
    { name: 'Iniciar sesión', href: '/login', icon: <ArrowRightEndOnRectangleIcon className="h-6 w-6" />, id: 'login' },
    { name: 'Registrarse', href: '/register', icon: <UserPlusIcon className="h-6 w-6" />, id: 'registro' },
    { name: 'Acreditaciones', href: '/login_acreditacion', icon: <PlusCircleIcon className="h-6 w-6" />, id: 'acreditacion' },
    { name: 'Facturas Econocell', href: '/comprobante', icon: <DocumentTextIcon className="h-6 w-6" />, id: 'facturas' },
    { name: 'Contactos', href: '/contact', icon: <UserGroupIcon className="h-6 w-6" />, id: 'contacto' },
];

const Sidebar = () => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i) || window.innerWidth <= 780) {
            setOpen(false)
        } else {
            setOpen(true)
        }
    }, []);

    return (
        <>
            <div
                className={` ${open ? "w-40 sm:w-56" : "w-16 sm:w-20 "
                    } bg-amber-500 h-screen p-2 sm:p-5 pt-8 relative duration-300 shadow-lg`}>
                <ChevronLeftIcon
                    className={`absolute cursor-pointer -right-3 top-9 w-6 h-6 border-amber-600 bg-amber-600
           			border-2 rounded-full z-20  ${!open && "rotate-180"}`}
                    onClick={() => setOpen(!open)}
                />
                <Link
                    to="/"
                    aria-label="Inicio"
                >
                    <div className="flex gap-x-4 items-center">
                        <img
                            src='/img/Nav/logo.png' alt=""
                            className={`duration-500 h-9 w-9 ${open && "rotate-[360deg]"
                                }`}
                        />

                        <h1
                            className={`text-white origin-left font-medium text-sm sm:text-xl duration-200 ${!open && "scale-0"
                                }`}
                        >
                            RecargaFácil
                        </h1>
                    </div>
                </Link>
                <div className="pt-6">
                    {navigation.map((item) => (
                        <Tooltip placement="right" overlay={item.name} key={item.name}>
                            <NavLink
                                to={item.href}
                                className="flex rounded-md my-1 p-2 hover:bg-blue-500 text-white font-semibold text-xs sm:text-sm items-center gap-x-4"
                                activeclassname="active"
                                id={item.id}
                                aria-label={item.name}
                            >
                                {item.icon}
                                <span className={`${!open && "hidden"} origin-left duration-200`}
                                >
                                    {item.name}
                                </span>
                            </NavLink>
                        </Tooltip>
                    ))}
                </div>
            </div>

            <div className="h-screen flex-1 p-0 overflow-x-auto">
                <div className="px-2">
                    <Outlet />
                </div>
                <Footer />
            </div>
        </>
    );
};
export default Sidebar;