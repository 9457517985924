import { gql } from '@apollo/client';

export const ADD_VISTA = gql`
mutation add_vista($fk_cliente: Int!, $fk_publicidad: Int!){
    addVista(fk_cliente: $fk_cliente, fk_publicidad: $fk_publicidad)
}
`;

export const ADD_CAROUSEL_LOCAL = gql`
mutation addCarouselLocal($imagen: Upload!, $titulo: String!, $fkCliente: Float!, $descripcion: String, $precio: Float, $pvp: Float){
  addCarouselLocal(imagen: $imagen, titulo: $titulo, fkCliente: $fkCliente, descripcion: $descripcion, precio: $precio, pvp: $pvp)
}
`;

export const ADD_PRODUCTO_CLIENTE = gql`
mutation addProductoCliente($nombre: String!, $imagen: Upload!, $costo: Float!, $precio: Float!, $stock: Int!, $visible: String!, $descripcion: String, $pvp: Float, $fkTienda: Float!, $links: String){
  addProductoCliente(nombre: $nombre, imagen: $imagen, costo: $costo, precio: $precio, stock: $stock, visible: $visible, descripcion: $descripcion, pvp: $pvp, fkTienda: $fkTienda, links: $links)
}
`;

export const EDIT_PRODUCTO_CLIENTE = gql`
mutation editProductoCliente($codigo: Float!, $nombre: String!, $costo: Float!, $precio: Float!, $stock: Int!, $visible: String!, $imagen: Upload, $descripcion: String, $pvp: Float, $links: String){
  editProductoCliente(codigo: $codigo, nombre: $nombre, costo: $costo, precio: $precio, stock: $stock, visible: $visible, imagen: $imagen, descripcion: $descripcion, pvp: $pvp, links: $links)
}
`;

export const UPDATE_CONFIGURACION_VITRINA = gql`
mutation updateConfiguracionVitrina($fkCliente: Float!, $vitrina: String!, $minutos: Float!){
  updateConfiguracionVitrina(fkCliente: $fkCliente, vitrina: $vitrina, minutos: $minutos)
}
`;

export const EDIT_VISIBLE_VITRINA = gql`
mutation editVisibleVitrina($id: Float!, $visible: String!){
  editVisibleVitrina(id: $id, visible: $visible)
}
`;

export const ADD_TIENDA = gql`
mutation addTienda($nombre: String!, $fkCliente: Float!, $envioLocal: Float!, $envioFuera: Float!, $horario: String!, $imagen: Upload, $visible: String!, $fkCiudad: Int!){
  addTienda(nombre: $nombre, fkCliente: $fkCliente, envioLocal: $envioLocal, envioFuera: $envioFuera, horario: $horario, imagen: $imagen, visible: $visible, fkCiudad: $fkCiudad)
}
`;

export const UPDATE_TIENDA = gql`
mutation updateTienda($id: Float!, $nombre: String!, $envioLocal: Float!, $envioFuera: Float!, $horario: String!, $imagen: Upload, $visible: String!, $fkCiudad: Int!){
  updateTienda(id: $id, nombre: $nombre, envioLocal: $envioLocal, envioFuera: $envioFuera, horario: $horario, imagen: $imagen, visible: $visible, fkCiudad: $fkCiudad)
}
`;

export const EDIT_PRODUCTO_VITRINA = gql`
mutation editVitrinaProducto($fkCliente: Float!, $detalle: [DetalleVitrina]!){
  editVitrinaProducto(fkCliente: $fkCliente, detalle: $detalle)
}
`;